import { PageProps } from 'gatsby';
import { DiscountData } from 'packages/innedit';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '~/components/List/Item/Discount';
import CMSView from '~/components/View';
import List from '~/containers/Espace/List';
import params from '~/params/subscription.json';
import TemplateEspace from '~/templates/espace';
import requireEspace, { EspaceProps } from '~/utils/requireEspace';
import { UserProps } from '~/utils/requireUser';
import useProduits from '~/utils/useProduits';

const DiscountsPage: FC<PageProps & EspaceProps & UserProps> = ({
  location,
  espace,
  user,
  params: { espaceId },
}) => {
  const { t } = useTranslation();
  const items = useProduits({
    espaceId: espace.id,
    pathname: location.pathname,
  });

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espaceId}/produits/discounts/`}
          model={
            new DiscountData({
              espaceId,
              params,
            })
          }
          pathname={location.pathname}
          search={location.search}
          subMenu={items}
          title={t('produits.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(DiscountsPage);
